// Set the starting point from where alerts are fetched
export const setAlertFetchedFrom = (state, data) => {
  state.alertFetchedFrom = data;
};

// Set the endpoint up to where alerts are fetched
export const setAlertFetchedTo = (state, data) => {
  state.alertFetchedTo = data;
};

// Set the 'from' date for alert filtering
export const setAlertDateFrom = (state, data) => {
  state.alertDateFrom = data;
};

// Set the 'to' date for alert filtering
export const setAlertDateTo = (state, data) => {
  state.alertDateTo = data;
};

// Set the live status of the alert
export const setAlertLive = (state, data) => {
  state.alertLive = data;
};

// Set the filter criteria for alerts
export const setAlertFilter = (state, data) => {
  state.alertFilter = data;
};

// Set the flag indicating whether alerts have been fetched
export const setAlertFetched = (state, data) => {
  state.alertFetched = data;
};

// Set the status of the alert connection
export const setAlertConnection = (state, data) => {
  state.alertConnection = data;
};

// Set the 'from' date for report filtering
export const setReportDateFrom = (state, data) => {
  state.reportDateFrom = data;
};

// Set the 'to' date for report filtering
export const setReportDateTo = (state, data) => {
  state.reportDateTo = data;
};

// Set the live status of the report
export const setReportLive = (state, data) => {
  state.reportLive = data;
};

// Set the warehouse associated with the report
export const setReportWarehouse = (state, data) => {
  state.reportWarehouse = data;
};
