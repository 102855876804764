// Import moment for date manipulation
import moment from "moment";

// Import axios for HTTP requests
import axios from "axios";

// Service Imports
import { ReportsApi } from "src/services/api";
import { axiosErrorHandler } from "src/services/errors";

// Action to fetch all reports from the API
export const getAllReports = async ({ commit }) => {
  try {
    const { data } = await ReportsApi.get(); // Fetch all reports from API
    if (data.scripts) {
      commit("setReportsData", data);
    } else {
      commit("setReportsData", { scripts: [] });
    }
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to get generated report files by report ID
export const getGeneratedReportFiles = async ({ commit }, reportId) => {
  try {
    const { data } = await ReportsApi.getFiles(reportId); // Fetch report files based on report ID
    if (data.report_files) {
      commit("setGeneratedReports", data);
    } else {
      commit("setGeneratedReports", { report_files: [] });
    }
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to fetch the status of all reports within the last 24 hours
export const getReportsStatus = async ({ commit }) => {
  try {
    const { data } = await ReportsApi.getHistory({ report: "ALL", duration: "24" }); // Fetch report status with a 24-hour duration
    commit("setReportsStatus", data);
    commit("setReportsNotifications", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to fetch execution history of a specific report for the last 720 hours (30 days)
export const getExecutionHistory = async ({ commit }, { reportName }) => {
  try {
    const { data } = await ReportsApi.getHistory({ report: reportName, duration: "720" }); // Fetch execution history for a report
    commit("setExecutionHistory", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to get report parameters by report name
export const getReportParams = async (context, reportName) => {
  try {
    const { data } = await ReportsApi.getParams(reportName); // Fetch report parameters by report name
    return data.parameters;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to run a report script with specific parameters
export const runReportScript = async ({ commit }, { report, params }) => {
  try {
    const generated_at = moment(Date.now()).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); // Get current UTC timestamp
    commit("addReportStatusCard", { status: "In-Progress", name: report.name, generated_at }); // Add report status card as "In-Progress"
    commit("updateReportStatus", { status: { status: "In-Progress" }, report }); // Update report status as "In-Progress"

    const { data } = await ReportsApi.post({ "script-name": report.name, params }); // Run the report script via API
    const status = { report_id: data.report_id, status: data.status, generated_at }; // Prepare status object with report ID and status

    commit("updateReportStatus", { status, report }); // Update the report status
    commit("updateReportStatusCard", { status, report }); // Update the report status card
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to fetch report file data by URL
export const getReportFileData = async (context, url) => {
  try {
    const { data } = await axios.get(url, { responseType: "blob" }); // Fetch file data from the provided URL as a blob
    return data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};
