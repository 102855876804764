// Import helpers and services
import { device_types, isLabel, isTracker } from "src/services/deviceTypes";
import { fullFormatter, toLocal } from "src/helpers/date";

// Set the access token in the state
export const setAccessToken = (state, accessToken) => {
  state.accessToken = accessToken;
};

// Set user information in the state
export const setUserInfo = (state, data) => {
  state.userInfo = data;
};

// Update the label status location type in the state
export const updateLocationsType = (state, locationType) => {
  state.labelStatusLocationsType = locationType;
};

// Edit location and enable location edit mode if a location is provided
export const editLocation = (state, location) => {
  state.locationEdit = location;

  if (location) {
    state.locationsEditDraw = true;
  }
};

// Update a specific field of the location being edited
export const setLocationField = (state, field) => {
  state.locationEdit = {
    ...state.locationEdit,
    ...field,
  };
};

// Toggle the sidebar settings visibility
export const toggleSidebarSettings = (state, data) => {
  state.sidebarSettings = data;
};

// Set the default parameters in the state
export const setDefaultParameters = (state, data) => {
  state.defaultParameters = data;
};

// Add a new notification to the notifications list
export const setNotification = (state, data) => {
  state.notifications.push(data);
};

// Remove a notification by its ID
export const deleteNotification = (state, id) => {
  const index = state.notifications.findIndex((n) => n.id === id);
  if (index > -1) {
    state.notifications[index].close(); // Trigger close action for notification
    state.notifications.splice(index, 1); // Remove notification from the list
  }
};

// Set the map filter type in the state
export const setMapFilterType = (state, data) => {
  state.mapFilterType = data;
};

// Add or remove a filter from the map filter list
export const setMapFilterList = (state, data) => {
  const index = state.mapFilterList.findIndex((n) => n === data);

  if (index > -1) {
    state.mapFilterList.splice(index, 1); // Remove filter if already present
  } else {
    state.mapFilterList.push(data); // Add filter if not present
  }
};

// Prevent map from focusing on specific elements
export const setPreventMapFocus = (state, data) => {
  state.mapPreventFocus = data;
};

// Set the last known position on the map
export const setMapLastPosition = (state, data) => {
  state.mapLastPosition = data;
};

// Set the indoor history data in the state
export const setIndoorHistory = (state, data) => {
  state.indoorHistory = data;
};

// Set the indoor clusters data in the state
export const setIndoorClusters = (state, data) => {
  state.indoorClusters = data;
};

// Update the provisioning table context with new data
export const setProvisioningTableContext = (state, val) => {
  state.provisioningTableContext = { ...state.provisioningTableContext, ...val };
};

// Clear the provisioning table context
export const clearProvisioningTableContext = (state) => {
  state.provisioningTableContext = {};
};

// Add a socket to the stream interruption banner and optionally set a close function
export const setStreamInterruptionBanner = (state, val) => {
  if (!state.streamInterruptionBanner.sockets.includes(val.socket)) {
    state.streamInterruptionBanner.sockets.push(val.socket);
    if (val.close) {
      state.streamInterruptionBanner.close = val.close;
    }
  }
};

// Remove a socket from the stream interruption banner and close the banner if no sockets remain
export const clearStreamInterruptionBanner = (state, val) => {
  if (state.streamInterruptionBanner) {
    const index = state.streamInterruptionBanner.sockets.findIndex((el) => el === val.socket);
    if (index > -1) {
      state.streamInterruptionBanner.sockets.splice(index, 1);

      if (state.streamInterruptionBanner.sockets.length === 0) {
        state.streamInterruptionBanner?.close(); // Close the banner if no sockets remain
        state.streamInterruptionBanner.close = undefined;
      }
    }
  }
};

// Set the filter used for history
export const setHistoryFilter = (state, data) => {
  state.historyFilter = data;
};

// Set the zone history settings in the state
export const setZoneHistorySettings = (state, data) => {
  state.zoneHistorySettings = data;
};

// Set the sensor history popup details
export const setSensorHistoryPopupDetail = (state, detail) => {
  state.sensorHistoryPopupDetail = detail;
};

// Set the sensor temperature graph data in the state
export const setSensorTempGraph = (state, detail) => {
  state.sensorTempGraph = detail;
};

// Set the sensor battery graph data in the state
export const setSensorBatGraph = (state, detail) => {
  state.sensorBatGraph = detail;
};

const formatCost = (cost) => {
  return Number.isInteger(cost) ? cost : cost.toFixed(2);
}

// Set the calculated billing data in the state
export const setBillingDetails = (state, data) => {
  const calculatedData = [
    {
      category: "Label",
      count: 0,
      price: 0,
      totalCost: 0,
    },
    {
      category: "Cellular Tracker",
      count: 0,
      price: 0,
      totalCost: 0,
    },
  ];

  let totalCost = 0;

  data?.devices?.forEach((el) => {
    if (device_types.label.includes(el.device_type)) {
      calculatedData[0].count++;
      calculatedData[0].totalCost += el.cost;
    } else {
      calculatedData[1].count++;
      calculatedData[1].totalCost += el.cost;
    }

    totalCost += el.cost;
  });

  calculatedData[0].totalCost = formatCost(calculatedData[0].totalCost);
  calculatedData[1].totalCost = formatCost(calculatedData[1].totalCost);
  totalCost = formatCost(totalCost);

  for (const price of data?.pricing || []) {
    if (calculatedData[0].price && calculatedData[1].price) break;
    console.log(price.device_type, calculatedData[0].price, calculatedData[1].price, isLabel(price.device_type) && !calculatedData[0].price, isTracker(price.device_type) && !calculatedData[1].price)
    if (isLabel(price.device_type)) {
      calculatedData[0].price = price.price;
    } else if (isTracker(price.device_type)) {
      calculatedData[1].price = price.price;
    }
  }

  const dataOverview = [calculatedData[0].totalCost, calculatedData[1].totalCost];

  state.billingDetails = {
    totalCost,
    calculatedData,
    dataOverview,
    devices: data.devices ?? [],
  };
};

// Set the device info in the state
export const setDeviceDetails = (state, data) => {
  const deviceInfo = {
    "Active since timestamp": fullFormatter(toLocal(data.active_since)),
    "Active seen timestamp": fullFormatter(toLocal(data.active_seen)),
    Category: device_types.label.includes(data.device_type) ? "Label" : "Cellular Tracker",
    Pricing: `$${data.pricing.price}/${data.pricing.period}`,
    "Total cost": `$${data.total_cost}`,
  };

  state.deviceDetails = deviceInfo;
};
