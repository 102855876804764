/**
 * Get a specific mesh bridge from the state by its ID
 *
 * @param {Object} state - Vuex state object
 * @param {String} id - ID of the mesh bridge to find
 * @returns {Object} - The mesh bridge object that matches the given ID
 */
export const getMeshBridge = (state) => (id) => state.meshBridges.find((el) => el.bridge_id === id);

/**
 * Combine both warehouses and remote locations into a single array
 *
 * @param {Object} state - Vuex state object
 * @returns {Array} - Array that combines both warehouses and remote locations
 */
export const bothTypes = (state) => [...state.warehouses, ...state.remoteLocations];
