// Vue Imports
import { createStore } from "vuex";

// State Imports
import uiState from "./ui-state";
import nodeState from "./node-state";
import dashboard from "./dashboard";
import reportState from "./report-state";

export default function () {
  const Store = createStore({
    modules: {
      uiState,
      nodeState,
      dashboard,
      reportState,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });

  return Store;
}
