<template>
  <q-breadcrumbs v-if="breadcrumbs.length" gutter="xs" active-color="grey" separator-color="grey" class="q-mb-md u-text-sm">
    <q-breadcrumbs-el
      v-for="item in breadcrumbs"
      :key="item.to"
      :label="item.label"
      :to="push(item.to)"
      :class="{
        'text-dark': !item.to,
      }"
    />
  </q-breadcrumbs>
</template>

<script setup>
  // Vue Imports
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";

  const props = defineProps({
    data: {
      type: [Object, String],
      default: null,
    },
  });

  // Vue Route and store
  const $route = useRoute();
  const $store = useStore();

  // Breadcrumbs Computed Property
  const breadcrumbs = computed(() => {
    if (!$route.meta?.breadcrumbs) {
      return [];
    }

    if (typeof $route.meta.breadcrumbs === "function") {
      return $route.meta.breadcrumbs($route, $store, props.data);
    }

    return $route.meta.breadcrumbs;
  });

  /**
   * Function to handle navigation logic
   * Converts a string or object into a navigation route object
   */
  const push = (to) => {
    if (!to) {
      return null;
    }

    if (typeof to === "string") {
      return {
        name: to,
      };
    }

    return to;
  };
</script>

<style lang="scss">
  .q-breadcrumbs {
    .q-router-link--active {
      transition: color 0.2s;

      &:hover {
        color: $dark;
      }
    }
  }
</style>
