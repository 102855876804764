<template>
  <div :class="{ invisible: !isShow }" class="flex flex-center" :style="`height: ${height}`">
    <q-spinner :color="color" :size="size" :thickness="thickness" />
  </div>
</template>

<script setup>
  // Vue Imports
  import { ref, onUnmounted } from "vue";

  // These props configure the spinner.
  const props = defineProps({
    height: {
      type: String,
      default: "200px",
    },
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "3em",
    },
    thickness: {
      type: Number,
      default: 2,
    },
    delay: {
      type: Number,
      default: 0,
    },
  });

  // Reactive Data
  const isShow = ref(false);

  /**
   * Set a timeout to show the element after the specified delay
   */
  const timeout = setTimeout(() => {
    isShow.value = true;
  }, props.delay);

  onUnmounted(() => {
    clearTimeout(timeout); // Prevent memory leaks by clearing the timeout
  });
</script>
