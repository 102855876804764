<template>
  <div :class="{ invisible: !isShow }" class="q-gutter-sm q-ml-none">
    <slot />
  </div>
</template>

<script setup>
  // Vue Imports
  import { ref, onUnmounted } from "vue";

  const props = defineProps({
    delay: {
      type: Number,
      default: 250,
    },
  });

  // Reactive Data
  const isShow = ref(false);

  /**
   * Set a timeout to show the element after the specified delay
   */
  const timeout = setTimeout(() => {
    isShow.value = true;
  }, props.delay);

  onUnmounted(() => {
    clearTimeout(timeout); // Prevent memory leaks by clearing the timeout
  });
</script>
