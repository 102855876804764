<template>
  <div class="flex items-center no-wrap">
    <span
      class="q-mr-sm"
      :class="{
        'bg-positive': deviceInfo.status === 'ACTIVE',
        'bg-negative': deviceInfo.status === 'INACTIVE',
        'boolean-circle': isLabel(deviceInfo.device_type),
        'boolean-square': isTracker(deviceInfo.device_type),
      }"
    />
    <span>
      {{ formatValue(deviceInfo[field]) }}
    </span>
  </div>
</template>

<script setup>
  import { isLabel, isTracker } from "../../services/deviceTypes";

  defineProps({
    deviceInfo: {
      type: Object,
      requried: true,
    },
    field: {
      type: String,
      required: true,
    },
  });

  /**
   * Function to update value to user firendly format
   * @param {*} val
   */
  const formatValue = (val) => {
    if (val === undefined || val === null || val === "") return "—";
    return val;
  };
</script>
