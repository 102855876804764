<template>
  <q-dialog v-model="value" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar :icon="icon" :color="color" text-color="white" />
        <span class="q-ml-sm tw-max-w-[400px]">{{ description }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn v-if="cancelBtn" flat :label="cancelBtnLabel" color="primary" v-close-popup />
        <q-btn flat :label="submitBtnLabel" color="primary" v-close-popup @click="onSubmit" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
  import { computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "priority_high",
    },
    color: {
      type: String,
      default: "primary",
    },
    description: {
      type: String,
      required: true,
    },
    cancelBtn: {
      type: Boolean,
      default: true,
    },
    cancelBtnLabel: {
      type: String,
      default: "Cancel",
    },
    submitBtnLabel: {
      type: String,
      default: "OK",
    },
  });
  const emit = defineEmits(["update:modelValue", "submit"]);

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit("update:modelValue", value);
    },
  });

  const onSubmit = () => {
    emit("submit");
  };
</script>
