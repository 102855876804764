<template>
  <div :class="{ invisible: !isShow }" class="o-infinite-table">
    <q-markup-table>
      <thead>
        <tr>
          <th v-if="checkbox" width="90px">
            <q-skeleton animation="blink" type="QCheckbox" />
          </th>

          <th v-for="(column, index) in columns" :key="`th-${index}`">
            <q-skeleton animation="blink" type="text" :width="`${(column?.label?.length > 3 ? column?.label?.length : 3) * 7}px`" />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="n in rows" :key="`tr-${n}`">
          <td v-if="checkbox" width="90px">
            <q-skeleton animation="blink" type="QCheckbox" />
          </td>

          <td v-for="(column, index) in columns" :key="`td-${index}`">
            <q-skeleton animation="blink" type="text" :width="`${column.skeleton * 7}px`" />
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script setup>
  // Vue Imports
  import { ref, onUnmounted } from "vue";

  // These props configure the table, controlling checkbox display, number of rows, columns, and delay before showing content.
  const props = defineProps({
    checkbox: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Number,
      default: 3,
    },
    delay: {
      type: Number,
      default: 250,
    },
  });

  // Reactive Data
  const isShow = ref(false);

  /**
   * Set a timeout to show the element after the specified delay
   */
  const timeout = setTimeout(() => {
    isShow.value = true;
  }, props.delay);

  onUnmounted(() => {
    clearTimeout(timeout); // Prevent memory leaks by clearing the timeout
  });
</script>

<style lang="scss" scoped>
  .o-infinite-table {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -5px;
      right: -5px;
      bottom: -5px;
      height: 100px;
      background: linear-gradient(180deg, rgba(#fff, 0) 0%, rgba(#fff, 1) 95%);
      z-index: 1;
    }
  }
</style>
