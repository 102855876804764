import { Notify } from "quasar";

const errorCodes = {
  1000: "Your request couldn't succeed, please try again",
};

export const axiosErrorHandler = (error) => {
  const { request, response, config, message } = error;
  if (response) {
    if (response.data) {
      let { name, message, id } = response.data;
      message = errorCodes[+id] ?? message;

      Notify.create({
        icon: "dangerous",
        message: name,
        caption: message ?? response.data,
        color: "negative",
        timeout: 3000,
      });
    }
  } else if (request) {
    console.log(request);
  } else {
    console.log("Error", message);
  }
  console.log(config);

  throw error;
};

export const localizationHandler = (location) => {
  Notify.create({
    icon: "announcement",
    message: location.name,
    caption: "No Indoor Localization available for this location",
    color: "white",
    textColor: "black",
    timeout: 3000,
    actions: [
      {
        color: "black",
        size: "xs",
        icon: "close",
        round: true,
      },
    ],
  });
};
