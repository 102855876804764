// Quasar boot file to initialize plugins and setup
import { boot } from "quasar/wrappers";

// Google Maps integration for Vue
import VueGoogleMaps from "@fawmi/vue-google-maps";

// Konva for canvas elements in Vue
import VueKonva from "vue-konva";

// DatePicker component for Vue
import VueDatePicker from "@vuepic/vue-datepicker";

// Custom draggable directive
import { draggableDirective } from "src/helpers/draggable";

// Custom resizeable directive
import { resizableDirective } from "src/helpers/resizeable";

// Import styles for Vue DatePicker
import "@vuepic/vue-datepicker/dist/main.css";

export default boot(({ app, store }) => {
  const requireComponent = require.context("components/UI", false, /\.vue$/); // Automatically require and register UI components

  requireComponent.keys().map((key) => {
    const name = key.replace(/(\.\/|\.vue)/g, ""); // Remove extra parts of component names
    app.component(name, requireComponent(key).default); // Register each component globally
  });

  app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCwFK5f94IUE-4QLzPei3QtvBWwkTAAFdM", // API key for Google Maps
      libraries: ["geometry", "drawing"], // Load additional Google Maps libraries
    },
  });
  app.directive("draggable", draggableDirective);
  app.directive("resizeable", resizableDirective);

  app.component("VueDatePicker", VueDatePicker);

  app.use(VueKonva);

  if (!window.Cypress) return;
  window.store = store;
});
