<template>
  <div class="c-drop-zone">
    <div
      v-bind="getRootProps()"
      :class="{
        'tw-bg-black/[.02] tw-border-solid': isDragActive,
        'tw-border-dashed': !isDragActive,
      }"
      class="c-drop-zone__inner tw-border tw-border-black/24 tw-rounded tw-cursor-pointer hover:tw-bg-black/[.02] tw-h-40 tw-flex tw-items-center tw-justify-center tw-transition tw-select-none"
    >
      <input v-bind="getInputProps()" />

      <div class="tw-text-center">
        <q-icon name="cloud_upload" size="2.5em" class="tw-opacity-20" />
        <p>
          Drop <b>{{ currentType.extension }}</b> file here or click to upload
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
  // Vue Imports
  import { ref } from "vue";
  import { useDropzone } from "vue3-dropzone";

  const props = defineProps({
    type: {
      type: String,
      default: "image", // Defaults to 'image' type if not provided
      validator: (value) => ["image", "spreadsheet"].includes(value), // Validates that the type is either 'image' or 'spreadsheet'
    },
  });
  const emit = defineEmits(["onAccept", "onReject"]);

  // Function to set allowed file extensions and MIME types based on the provided type.
  const setType = (type) => {
    switch (type) {
      case "spreadsheet": {
        return {
          extension: ".xls, .xlsx", // Allowed spreadsheet extensions
          mime: [
            "application/vnd.ms-excel", // Excel MIME types
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ],
        };
      }
      default: {
        return {
          extension: "image", // Allowed image extension
          mime: "image/*", // All image types accepted
        };
      }
    }
  };

  // Current file type configuration based on the prop 'type'
  const currentType = ref(setType(props.type));

  /**
   * Handle rejected file drops by emitting the 'onReject' event with the error details
   */
  const onDropRejected = (errors) => {
    emit("onReject", errors);
  };

  /**
   * Handle accepted file drops by emitting the 'onAccept' event with the accepted files
   */
  const onDropAccepted = (files) => {
    emit("onAccept", files);
  };

  /**
   *  Initialize the useDropzone hook with custom configurations.
   * Only one file can be uploaded at a time (multiple: false) and accepted file types are based on the current type.
   */
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected,
    onDropAccepted,
    multiple: false,
    accept: currentType.value.mime,
  });
</script>

<style lang="scss" scope>
  .q-field--error {
    .c-drop-zone {
      &__inner {
        border-color: var(--q-negative);
      }
    }
  }
</style>
