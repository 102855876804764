export default () => ({
  alerts: [],
  labels: [],
  trackers: [],
  meshBridges: [],
  nodeStates: [],
  remoteLocations: [],
  warehouses: [],
  crlSummaryReport: [],
  warehousesSummaryReport: [],
  labelRoundTripsReport: [],
  shipments: [],
  transports: [],
  mobileAnchors: [],
  zones: [],
  deviceHistory: [],
  uniqueDeviceHistory: [],
  selectedHistoryLocation: null,
  selectedHistoryLocationFromList: null,
  eventsFetched: false,
  sensorData: [],
  eventsData: [],
  zoneHistory: [],
});
