export const device_types = {
  label: [0, 1, 2, 3, 7],
  mobileTracker: [4, 5, 8],
  labelTracker: [6],
};

export const isLabel = (type) => device_types.label.includes(type);
export const isMobileTracker = (type) => device_types.mobileTracker.includes(type);
export const isLabelTracker = (type) => device_types.labelTracker.includes(type);
export const isTracker = (type) => [...device_types.labelTracker, ...device_types.mobileTracker].includes(type);
