// Import the v4 function from the 'uuid' library and rename it to 'uuidv4' for generating unique identifiers.
import { v4 as uuidv4 } from "uuid";
// Service Imports
import {
  MeshBridgeApi,
  RemoteLocationApi,
  WarehouseApi,
  NodeApi,
  LabelApi,
  TrackerApi,
  TransportApi,
  ShipmentApi,
  LocalizationApi,
  LocalizationHistoryApi,
  MobileAnchorApi,
  DeviceHistoryApi,
  EventsApi,
} from "src/services/api";
import { axiosErrorHandler } from "src/services/errors";

// Helper Imports
import { locationToFront, locationFromFront } from "src/helpers/locations";
import { toLocal, fullFormatter } from "src/helpers/date";

/**
 * Fetch all mesh bridges and commit them to the store
 */
export const getMeshBridges = async ({ commit }) => {
  try {
    const { data } = await MeshBridgeApi.get();
    commit("setMeshBridges", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch a specific mesh bridge by ID and commit it to the store
 */
export const getMeshBridge = async ({ commit }, id) => {
  try {
    const { data } = await MeshBridgeApi.get(id);
    commit("setMeshBridge", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new mesh bridge
 */
export const newMeshBridge = async (context, payload) => {
  try {
    await MeshBridgeApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing mesh bridge
 */
export const updateMeshBridge = async (context, payload) => {
  try {
    const { bridge_id, ...body } = payload;
    await MeshBridgeApi.update(bridge_id, body);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a mesh bridge by ID
 */
export const deleteMeshBridge = async (context, id) => {
  try {
    await MeshBridgeApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all warehouses and commit them to the store after transforming the data
 */
export const getWarehouses = async ({ commit }) => {
  try {
    const response = await WarehouseApi.get();
    const data = response.data.map((location) => locationToFront(location));
    commit("getWarehouses", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all remote locations and commit them to the store after transforming the data
 */
export const getRemoteLocations = async ({ commit }) => {
  try {
    const response = await RemoteLocationApi.get();
    const data = response.data.map((location) => locationToFront(location));
    commit("getRemoteLocations", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a warehouse by ID and commit the deletion to the store
 */
export const deleteWarehouse = async ({ commit }, id) => {
  try {
    await WarehouseApi.delete(id);
    commit("deleteWarehouse", id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a remote location by ID and commit the deletion to the store
 */
export const deleteRemoteLocation = async ({ commit }, id) => {
  try {
    await RemoteLocationApi.delete(id);
    commit("deleteRemoteLocation", id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new warehouse and commit the result to the store
 */
export const createWarehouse = async ({ commit }, payload) => {
  try {
    const { data } = await WarehouseApi.create(locationFromFront(payload));
    commit("updateWarehouse", {
      ...locationToFront(data),
      localization: false,
    });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new remote location and commit the result to the store
 */
export const createRemoteLocation = async ({ commit }, payload) => {
  try {
    const { data } = await RemoteLocationApi.create(locationFromFront(payload));
    commit("updateRemoteLocation", locationToFront(data));
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing warehouse and commit the result to the store
 */
export const updateWarehouse = async ({ commit }, payload) => {
  try {
    const { id, name, localization, ...body } = payload;
    const { data } = await WarehouseApi.update(id, name, body);
    commit("updateWarehouse", {
      ...locationToFront(data),
      localization,
    });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing remote location and commit the result to the store
 */
export const updateRemoteLocation = async ({ commit }, payload) => {
  try {
    const { id, name, ...body } = payload;
    const { data } = await RemoteLocationApi.update(id, name, body);
    commit("updateRemoteLocation", locationToFront(data));
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Set node states and commit them to the store
 */
export const setNodeStates = async ({ commit }, payload) => {
  try {
    const { data } = await NodeApi.get(payload);
    commit("setNodeStates", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch node states and return the data
 */
export const getNodeStates = async (context, payload) => {
  try {
    const { data } = await NodeApi.get(payload);
    return data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Clear all node states from the store
 */
export const clearNodeStates = ({ commit }) => {
  commit("clearNodeStates");
};

/**
 * Add a new node or update an existing one in the store
 */
export const addNode = async ({ commit, state }, payload) => {
  const { label } = payload;
  const index = state.nodeStates.findIndex((n) => n.node_id === label.node_id);
  payload.index = index;

  if (label.device_enable === true && index < 0) {
    const { data } = await NodeApi.get(["Label", [label.node_id]]);
    payload.data = data[0];
  }
  commit("setNodeState", payload);
};

/**
 * Clear anchor states from the store
 */
export const clearAnchorStates = ({ commit }) => {
  commit("clearAnchorStates");
};

/**
 * Set an alert and optionally clear the "new" status after a delay
 */
export const setAlert = async ({ commit }, payload) => {
  commit("setAlert", payload);

  if (payload.isNew) {
    setTimeout(() => {
      commit("clearNewAlert", payload);
    }, 3000);
  }
};

/**
 * Set a report in the store based on a message
 */
export const setReport = ({ commit }, message) => {
  commit("setReport", JSON.parse(message));
};

/**
 * Fetch all labels and commit them to the store
 */
export const getLabels = async ({ commit }) => {
  try {
    const { data } = await LabelApi.get();
    commit("setLabels", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch a specific label by ID
 */
export const getLabel = async (context, id) => {
  try {
    const { data } = await LabelApi.get(id);
    return data[0];
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all trackers and commit them to the store
 */
export const getTrackers = async ({ commit }) => {
  try {
    const { data } = await TrackerApi.get();
    commit("setTrackers", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch a specific tracker by ID
 */
export const getTracker = async (context, id) => {
  try {
    const { data } = await TrackerApi.get(id);
    return data[0];
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new label
 */
export const newLabel = async (context, payload) => {
  try {
    await LabelApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new tracker
 */
export const newTracker = async (context, payload) => {
  try {
    await TrackerApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing label
 */
export const updateLabel = async (context, payload) => {
  try {
    await LabelApi.update({ labels: payload });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing tracker
 */
export const updateTracker = async (context, payload) => {
  try {
    await TrackerApi.update({ trackers: payload });
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a label by ID
 */
export const deleteLabel = async (context, id) => {
  try {
    await LabelApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a tracker by ID
 */
export const deleteTracker = async (context, id) => {
  try {
    await TrackerApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all transports and commit them to the store
 */
export const getTransports = async ({ commit }) => {
  try {
    const { data } = await TransportApi.get();
    commit("setTransports", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch a specific transport by ID
 */
export const getTransport = async (context, id) => {
  try {
    const { data } = await TransportApi.get(id);
    return data[0];
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new transport
 */
export const newTransport = async (context, payload) => {
  try {
    await TransportApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing transport
 */
export const updateTransport = async (context, payload) => {
  try {
    const { transport_id, ...body } = payload;
    await TransportApi.update(transport_id, body);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a transport by ID
 */
export const deleteTransport = async (context, id) => {
  try {
    await TransportApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all shipments and commit them to the store
 */
export const getShipments = async ({ commit }) => {
  try {
    const { data } = await ShipmentApi.get();
    commit("setShipments", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch a specific shipment by ID
 */
export const getShipment = async (context, id) => {
  try {
    const { data } = await ShipmentApi.get(id);
    return data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new shipment
 */
export const newShipment = async (context, payload) => {
  try {
    await ShipmentApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing shipment
 */
export const updateShipment = async (context, payload) => {
  try {
    const { asn_id, ...body } = payload;
    delete body.labels_count;
    await ShipmentApi.update(asn_id, body);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a shipment by ID
 */
export const deleteShipment = async (context, id) => {
  try {
    await ShipmentApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch all localization data and format it before returning
 */
export const getLocalizations = async () => {
  try {
    const { data } = await LocalizationApi.get();
    return data.map((item) => ({
      ...item,
      update_timestamp: fullFormatter(toLocal(item.update_timestamp)),
    }));
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch specific localization data by ID and commit localization zones to the store
 */
export const getLocalization = async ({ commit }, id) => {
  try {
    const { data } = await LocalizationApi.get(id);
    commit("setLocalizationZones", data.localization_zones_info);
    return data;
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Create a new localization
 */
export const newLocalization = async (context, payload) => {
  try {
    await LocalizationApi.create(payload);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Update an existing localization
 */
export const updateLocalization = async (context, payload) => {
  try {
    const { localization_id, ...body } = payload;
    delete body.localization_name;
    delete body.plan_image;
    await LocalizationApi.update(localization_id, body);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Delete a localization by ID
 */
export const deleteLocalization = async (context, id) => {
  try {
    await LocalizationApi.delete(id);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch localization history from the API, format the timestamp, generate unique IDs for each history entry,
 * and commit the customized data to the store for easier management and display.
 */
export const getLocalizationHistory = async ({ commit }, payload) => {
  try {
    const { data } = await LocalizationHistoryApi.get(payload);
    const customizedData = data.history
      ? data.history.map((item) => ({
          ...item,
          update_timestamp: fullFormatter(new Date(item.update_timestamp)),
          id: uuidv4(),
        }))
      : [];
    commit("setLocalizationHistory", customizedData);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch mobile anchors for a specific warehouse and commit them to the store
 */
export const setMobileAnchors = async ({ commit }, warehouseID) => {
  try {
    const { data } = await MobileAnchorApi.get(warehouseID);
    commit("setMobileAnchors", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Set a specific mobile anchor in the store
 */
export const setMobileAnchor = ({ commit }, payload) => {
  commit("setMobileAnchor", payload);
};

/**
 * Clear all mobile anchors from the store
 */
export const clearMobileAnchors = ({ commit }) => {
  commit("clearMobileAnchors");
};

/**
 * Clear mobile tracker history from the store
 */
export const clearMobileTrackerHistory = ({ commit }) => {
  commit("clearMobileTrackerHistory");
};

/**
 * Fetch device history and commit it to the store
 */
export const getDeviceHistory = async ({ commit }, payload) => {
  try {
    const { data } = await DeviceHistoryApi.get(payload);
    commit("setDeviceHistory", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch sensor data and commit it to the store
 */
export const getSensorData = async ({ commit }, payload) => {
  try {
    const { data } = await DeviceHistoryApi.get(payload);
    commit("setSensorData", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

/**
 * Fetch events data and commit it to the store
 */
export const getEventsData = async ({ commit }, payload) => {
  try {
    commit("clearEventsData");
    if (payload.length) {
      commit("setEventsFetched", false);
      const { data } = await EventsApi.get(payload);
      commit("setEventsData", data);
      commit("setEventsFetched", true);
    }
  } catch (error) {
    axiosErrorHandler(error);
  }
};
