export const resizableDirective = {
  /**
   * Mounted hook for the custom Vue directive, which adds a resize handle to the element
   * and allows the user to resize the element.
   *
   * @param {HTMLElement} el - The element to which the directive is bound.
   * @param {Object} binding - The binding object containing optional modifiers like 'restrictMaxSize' and 'aspected'.
   */
  mounted(el, binding) {
    // Create a resize handle that the user can grab to resize the element
    const resizeHandle = document.createElement("div");

    // Styles for the resize handle (a small box at the bottom-right corner)
    const resizeHandleStyles = {
      width: "15px",
      height: "15px",
      cursor: "se-resize",
      position: "absolute",
      bottom: "0",
      right: "0",
      background: "repeating-linear-gradient( -45deg,  black,  black 2.5px, #F3F4F6 4px, #F3F4F6 8px)",
    };

    // Apply the styles to the resize handle
    for (let style in resizeHandleStyles) {
      resizeHandle.style[style] = resizeHandleStyles[style];
    }
    // Append the resize handle to the resizable element
    el.appendChild(resizeHandle);

    let isResizing = false;
    let startX, startY, startWidth, startHeight;

    /**
     * Resize event handler that updates the element's width and height as the mouse moves.
     */
    const resize = (e) => {
      if (!isResizing) return;
      document.body.style.userSelect = "none";
      document.querySelector(".q-field__native").style.userSelect = "none";

      const newWidth = startWidth + e.clientX - startX;
      let newHeight = startHeight + e.clientY - startY;

      // Restrict resizing to maxWidth and maxHeight if enabled
      if (binding.value["restrictMaxSize"] && (el.style.maxHeight === "" || el.style.maxWidth === "")) {
        el.style.maxHeight = el.style.height;
        el.style.maxWidth = el.style.width;
      }

      // Extract numeric values from minWidth, minHeight, maxWidth, and maxHeight
      const minWidth = Number(el.style.minWidth.replace("px", "")) || 0;
      const minHeight = Number(el.style.minHeight.replace("px", "")) || 0;
      const maxWidth = Number(el.style.maxWidth.replace("px", "")) || Infinity;
      const maxHeight = Number(el.style.maxHeight.replace("px", "")) || Infinity;

      if (binding.value["aspected"]) {
        const aspectRatio = Number(el.style.width.replace("px", "")) / Number(el.style.height.replace("px", ""));
        newHeight = newWidth * aspectRatio;
      }

      // Ensure newWidth and newHeight are within the specified constraints
      el.style.width = Math.max(minWidth, Math.min(newWidth, maxWidth)) + "px";
      el.style.height = Math.max(minHeight, Math.min(newHeight, maxHeight)) + "px";
    };

    /**
     * Stops the resizing process, restores user selection, and cleans up event listeners.
     */
    const stopResize = () => {
      if (!isResizing) return;

      // Re-enable text selection
      document.body.style.userSelect = "";
      document.querySelector(".q-field__native").style.userSelect = "";

      // Stops resizing
      isResizing = false;
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", stopResize);

      // Dispatch a custom 'resizeend' event to indicate resizing has finished
      const resizeEndEvent = new Event("resizeend");
      el.dispatchEvent(resizeEndEvent);
    };

    /**
     * Begins the resizing process when the user clicks on the resize handle.
     */
    resizeHandle.addEventListener("mousedown", (e) => {
      isResizing = true;
      startX = e.clientX;
      startY = e.clientY;
      startWidth = el.offsetWidth;
      startHeight = el.offsetHeight;

      // Attach event listeners for mousemove (resize) and mouseup (stop resize)
      document.addEventListener("mousemove", resize);
      document.addEventListener("mouseup", stopResize);

      // Dispatch a custom 'resizestart' event to indicate resizing has started
      const resizeStartEvent = new Event("resizestart");
      el.dispatchEvent(resizeStartEvent);
    });
  },
};
