export default () => ({
  accessToken: null,
  userInfo: null,
  defaultParameters: null,
  sidebarSettings: false,
  viewMode: "map",
  labelStatusLocationsType: "warehouse",
  locationEdit: null,
  mapFilterType: null,
  mapFilterList: [],
  mapPreventFocus: false,
  mapLastPosition: null,
  notifications: [],
  stageWidth: 900,
  stagePadding: 25,
  indoorHistory: null,
  indoorClusters: null,
  provisioningTableContext: {},
  streamInterruptionBanner: { sockets: [], close: undefined },
  historyFilter: {},
  zoneHistorySettings: {},
  sensorHistoryPopupDetail: { show: false },
  sensorTempGraph: { show: false },
  sensorBatGraph: { show: false },
  billingDetails: {},
  deviceDetails: {},
});
