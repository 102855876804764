// Import jwt-decode to decode the JWT token
import jwt_decode from "jwt-decode";

// Import helpers and services
import { getUtcTimesForCurrentMonth } from "src/helpers/date";
import { BillingsApi, DefaultParametersApi } from "src/services/api";

// Import error handler for axios
import { axiosErrorHandler } from "src/services/errors";

// Action to update the access token and user info
export const updateAccessToken = ({ commit }, payload) => {
  let userInfo = null;
  if (payload) {
    const { email, name, picture } = jwt_decode(payload);
    userInfo = {
      email,
      name,
      picture,
    };
    localStorage.setItem("authEmail", email);
  } else {
    localStorage.removeItem("authEmail");
  }

  // Commit mutations to update access token and user info in the store
  commit("setAccessToken", payload);
  commit("setUserInfo", userInfo);
};

// Action to toggle sidebar settings
export const toggleSidebarSettings = ({ commit }, payload) => {
  commit("toggleSidebarSettings", payload);
};

// Action to fetch default parameters from API
export const getDefaultParameters = async ({ commit }) => {
  try {
    const { data } = await DefaultParametersApi.get(); // Fetch data from API
    commit("setDefaultParameters", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to update default settings via API
export const setDefaultSettings = async ({ commit }, payload) => {
  try {
    const { data } = await DefaultParametersApi.update(payload); // Update settings via API
    commit("setDefaultParameters", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to add a notification and remove the oldest if there are more than 3
export const setNotification = ({ dispatch, commit, state }, data) => {
  commit("setNotification", data);

  if (state.notifications.length > 3) {
    dispatch("deleteNotification", state.notifications[0].id);
  }
};

// Action to delete a notification by ID
export const deleteNotification = ({ commit }, id) => {
  commit("deleteNotification", id);
};

// Action to get a Billing details
export const getBillingDetails = async ({ commit }) => {
  try {
    const { firstDayUTC: startTime, lastDayUTC: endTime } = getUtcTimesForCurrentMonth();
    const { data } = await BillingsApi.getCurrentMonthCost({ start_time: startTime, end_time: endTime });

    commit("setBillingDetails", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to get a Device details
export const getDeviceDetails = async ({ commit }, { device_id }) => {
  try {
    const { firstDayUTC: startTime, lastDayUTC: endTime } = getUtcTimesForCurrentMonth();
    const { data } = await BillingsApi.getDeviceDetails({
      start_time: startTime,
      end_time: endTime,
      device_id,
    });
    commit("setDeviceDetails", data);
  } catch (error) {
    axiosErrorHandler(error);
  }
};

// Action to set sensor history popup details
export const setSensorHistoryPopupDetail = async ({ commit }, payload) => {
  commit("setSensorHistoryPopupDetail", payload);
};

// Action to set sensor temperature graph data
export const setSensorTempGraph = async ({ commit }, payload) => {
  commit("setSensorTempGraph", payload);
};

// Action to set sensor battery graph data
export const setSensorBatGraph = async ({ commit }, payload) => {
  commit("setSensorBatGraph", payload);
};
