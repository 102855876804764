// Import boot function from Quasar for app initialization
import { boot } from "quasar/wrappers";

// Import axios for making HTTP requests
import axios from "axios";

// Import axios-retry for retrying failed requests
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";

// Create axios instance with a dynamic base URL depending on the environment
const api = axios.create({
  baseURL: process.env.DEV ? "http://localhost:3000" : `/api`,
  // baseURL: process.env.DEV ? 'http://192.168.0.141:3000' : `/api`
});

// Set up axios retry logic
axiosRetry(api, {
  retries: 2,
  retryCondition: (e) => isNetworkOrIdempotentRequestError(e) && e.config.method !== "head",
});

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { axios, api };
