import { date } from "quasar";

export const dateFormat = "YYYY/MM/DD HH:mm";
export const filterDateFormat = "YYYY-MM-DD HH:mm";
export const maskFormat = "####/##/## ##:##";

export const dateFormatter = (value) => date.formatDate(value, dateFormat);

export const filterDateFormatter = (value) => date.formatDate(value, filterDateFormat);

export const filterDateUtcFormatter = (value) => date.formatDate(toUTC(value), filterDateFormat, { timezone: "UTC" });

export const dayFormatter = (value) => date.formatDate(value, "YYYY/MM/DD");

export const monthFormatter = (value) => date.formatDate(value, "YYYY/MM");

export const timeFormatter = (value) => date.formatDate(value, "HH:mm");

export const labelFormatter = (value) => date.formatDate(value, "HH:mm:ss MM/DD/YYYY");

export const fullFormatter = (value) => date.formatDate(value, "ddd D MMM YYYY HH:mm:ss");

export const historyFormatter = (value) => date.formatDate(value, "ddd D MMM YYYY HH:mm");

export const toUTC = (value) => new Date(value).toISOString().replace("T", " ").slice(0, -8);

export const toLocal = (value) => new Date(value + "Z");

export const getUtcTimesForCurrentMonth = () => {
  const now = new Date();

  // Get the first day of the current month at 00:00 UTC
  const firstDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1, 0, 0, 0));
  const firstDayUTC = date.formatDate(toUTC(firstDay), filterDateFormat);

  // Get the last day of the current month at 23:59 UTC
  const lastDay = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59));
  const lastDayUTC = date.formatDate(toUTC(lastDay), filterDateFormat);

  return {
    firstDayUTC,
    lastDayUTC,
  };
};
