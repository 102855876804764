/**
 * Bring the given element to the front by setting its z-index to be higher than all other draggable elements.
 * @param {HTMLElement} el - The element to bring to the front.
 */
const bringToFront = (el) => {
  // Find the highest z-index among all elements with the 'draggable' class
  const highestZIndex = Array.from(document.querySelectorAll(".draggable")).reduce((maxZIndex, element) => {
    const zIndex = parseInt(window.getComputedStyle(element).zIndex, 10) || 1;
    return Math.max(maxZIndex, zIndex);
  }, 0);
  el.style.zIndex = highestZIndex + 1;
};

/**
 * Rounds down a given dimension (in pixels) to the nearest multiple of 10.
 *
 * @param {string} dim - The dimension string (e.g., "123px").
 * @returns {number} - The rounded-down dimension as a number.
 */
const roundDown = (dim) => {
  return Math.floor(Number(dim.slice(0, -2)) / 10) * 10;
};

/**
 * Generates a position key for a given popup element based on its top and left positions.
 *
 * @param {HTMLElement} popup - The popup element.
 * @returns {string} - The position key in the format "left:top".
 */
const generatedPositionKey = (popup) => {
  const rect = popup.getBoundingClientRect();
  if (popup.style.left === "") popup.style.left = `${rect.left}px`;
  if (popup.style.top === "") popup.style.top = `${rect.top}px`;

  return `${roundDown(popup.style.left)}:${roundDown(popup.style.top)}`;
};

/**
 * Adjusts the positions of overlapping popups to ensure they are offset slightly from each other.
 */
const adjustPopupPositions = () => {
  const popups = Array.from(document.querySelectorAll(".draggable-offset")).sort((a, b) => a.style.zIndex - b.style.zIndex);

  const positions = {};

  // Group popups by their position key
  popups.forEach((popup) => {
    const posKey = generatedPositionKey(popup);

    if (positions[posKey]) {
      positions[posKey].push(popup);
    } else {
      positions[posKey] = [popup];
    }
  });

  // Adjust the position of overlapping popups
  Object.values(positions).forEach((popupsAtPosition) => {
    if (popupsAtPosition.length > 1) {
      popupsAtPosition.forEach((popup, index) => {
        if (index === popupsAtPosition.length - 1) {
          popup.style.transform = "translate(0, 0)"; // The top popup stays in place
        } else {
          // Offset the lower popups by -10px for each level
          const offset = -10 * (popupsAtPosition.length - 1 - index);
          popup.style.transform = `translate(${offset}px, ${offset}px)`;
        }
      });
    } else {
      popupsAtPosition[0].style.transform = "translate(0, 0)";
    }
  });
};

/**
 * A custom Vue directive to make elements draggable and handle overlaps.
 */
export const draggableDirective = {
  mounted(el, { value: { offsetOverlaps, scope, initPosition } }) {
    el.classList.add("draggable");
    if (offsetOverlaps) el.classList.add("draggable-offset");
    el.style.zIndex = scope === "global" ? 2000 : 100;
    el.style.boxShadow = "rgba(0, 0, 0, 0.16) 0px 1px 4px";

    // Bring the element to the front
    bringToFront(el);
    el.style.position = scope === "global" ? "fixed" : "absolute";

    // Set the initial position if provided
    if (initPosition) {
      initPosition.split("-").forEach((po) => {
        el.style[po] = po === "right" ? "20px" : "8px";
      });
    }

    // Adjust positions of overlapping popups
    if (offsetOverlaps) adjustPopupPositions();

    let initX, initY, clickX, clickY;
    let isResizing = false; // Flag to check if resizing is in progress

    // Handle mouse up event
    const mouseUp = () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
      el.style.cursor = "default";
      if (offsetOverlaps) adjustPopupPositions();
    };

    // Handle mouse move event to drag the element
    const mouseMove = (e) => {
      if (!isResizing && !e.target.classList.contains("no-drag")) {
        bringToFront(el);

        // Define restrictions based on scope
        const restrictedX = scope === "global" ? 0 : 320;
        const restrictedY = scope === "global" ? 0 : 70;

        // Define max boundaries
        const maxX = window.innerWidth - restrictedX - (el.style.width?.includes("px") ? Number(el.style.width.slice(0, -2)) : el.offsetWidth);
        const maxY = window.innerHeight - restrictedY - (el.style.height?.includes("px") ? Number(el.style.height.slice(0, -2)) : el.offsetHeight);

        // Calculate new positions, clamped within boundaries
        let newX = initX + e.clientX - clickX;
        let newY = initY + e.clientY - clickY;

        newX = Math.max(0, Math.min(newX, maxX));
        newY = Math.max(0, Math.min(newY, maxY));
        el.style.left = `${newX}px`;
        el.style.top = `${newY}px`;
      }
    };

    // Handle mouse down event to initiate drag
    const mouseDown = (e) => {
      e.preventDefault();
      initX = el.offsetLeft;
      initY = el.offsetTop;
      clickX = e.clientX;
      clickY = e.clientY;
      el.style.cursor = "grabbing";
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    };

    // Attach mouse down listener to the element for dragging
    el.addEventListener("mousedown", (e) => {
      if (!isResizing) {
        mouseDown(e);
      }
    });

    // Handle element resize events
    el.addEventListener("resizestart", () => {
      isResizing = true;
    });

    el.addEventListener("resizeend", () => {
      isResizing = false;
    });

    // Bring the element to the front on double-click
    el.addEventListener("dblclick", () => {
      bringToFront(el);
    });
  },
};
