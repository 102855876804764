// Service Imports
import { defaultReport } from "src/services/reports";

// Mutation to set reports data by mapping over the incoming script names
export const setReportsData = (state, data) => {
  const reportsData = state.reportsData;
  const temp = data.scripts.map((reportName) => {
    const index = reportsData.findIndex((el) => el.name === reportName);
    if (index > -1) return reportsData[index];
    else
      return {
        ...defaultReport,
        name: reportName,
      };
  });
  state.reportsData = temp;
};

// Mutation to update the status of reports
export const setReportsStatus = (state, data) => {
  Object.keys(data).forEach((key) => {
    const idx = state.reportsData.findIndex((item) => item.name === key);
    if (idx > -1) {
      const item = data[key][0];
      state.reportsData[idx] = {
        ...state.reportsData[idx],
        report_id: item.report_id,
        status: item.status,
        generated_at: item.generated_at,
      };
    }
  });
};

// Mutation to update the status of a specific report
export const updateReportStatus = (state, { status, report }) => {
  report = { ...report, ...status }; // Merge status into report object

  const index = state.reportsData.findIndex((el) => el.name === report.name);
  state.reportsData.splice(index, 1, report); // Replace the report with the updated one
};

// Mutation to set the notifications related to reports
export const setReportsNotifications = (state, data) => {
  const temp = [];
  Object.keys(data).forEach((name) => {
    if (Array.isArray(data[name])) {
      data[name].forEach((execution) => {
        temp.push({ ...execution, name }); // Add executions with report name
      });
    }
  });

  temp.sort((a, b) => new Date(b.generated_at) - new Date(a.generated_at)); // Sort by generated date

  state.reportsNotificationData = temp; // Update notifications in the state
};

// Mutation to update a specific report notification
export const updateReportsNotifications = (state, report) => {
  const index = state.reportsNotificationData.findIndex((el) => el.report_id === report.report_id);

  if (index > -1) {
    const newReportData = {
      ...state.reportsNotificationData[index],
      ...report,
    };

    state.reportsNotificationData.splice(index, 1, newReportData); // Replace existing notification
  } else {
    const newReportData = {
      ...report,
    };

    state.reportsNotificationData.unshift(newReportData); // Add new report notification if not found
  }
};

// Mutation to add a new status card for a report
export const addReportStatusCard = (state, data) => {
  state.reportsStatusCardsData.push({ ...defaultReport, ...data });
};

// Mutation to update an existing report status card
export const updateReportStatusCard = (state, { status, report }) => {
  const index = state.reportsStatusCardsData.findIndex((el) => el.name === report.name && el.generated_at === status.generated_at);

  if (index > -1) {
    state.reportsStatusCardsData[index] = { ...state.reportsStatusCardsData[index], ...status };
  }
};

// Mutation to delete a specific report status card
export const deleteReportStatusCard = (state, report) => {
  const index = state.reportsStatusCardsData.findIndex(
    (el) => el.name === report.name && el.generated_at === report.generated_at && el.report_id === report.report_id,
  );

  if (index > -1) {
    state.reportsStatusCardsData.splice(index, 1);
  }
};

// Mutation to set the generated reports data
export const setGeneratedReports = (state, data) => {
  state.generatedReports = Object.keys(data.report_files).map((key) => {
    return {
      name: key,
      url: data.report_files[key],
    };
  });
};

// Mutation to set the execution history for a report
export const setExecutionHistory = (state, data) => {
  for (const key in data) {
    if (key !== "message") {
      state.executionHistory = data[key];
      return;
    }
  }

  state.executionHistory = [];
};

// Mutation to update a specific execution history entry
export const updateExecutionHistory = (state, report) => {
  const index = state.executionHistory.findIndex((el) => el.report_id === report.report_id);

  if (index > -1) {
    const newExecutionData = {
      ...state.reportsNotificationData[index],
      ...report,
    };

    state.executionHistory.splice(index, 1, newExecutionData);
  } else {
    const newExecutionData = {
      ...report,
    };

    state.executionHistory.unshift(newExecutionData);
  }
};

// Mutation to clear the execution history
export const clearExecutionHistory = (state) => {
  state.executionHistory = [];
};

// Mutation to set the custom report connection state
export const setCustomReportConnection = (state, data) => {
  state.customReportConnection = data;
};
